import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FindMyCarService } from "src/app/service/find-my-car.service";
import * as CryptoJS from "crypto-js";
import { getVspecUrls, getFormattedDate } from "src/app/utils";

const TEMP_V_SPEC_URL = getVspecUrls()["webBase"] + "/v-spec";

@Component({
  selector: "app-dealer-popup",
  templateUrl: "./dealer-popup.component.html",
  styleUrls: ["./dealer-popup.component.css"],
})
export class DealerPopupComponent implements OnInit {
  within_dealer_vin: any = [];
  outside_dealer_vin: any = [];
  vehicleData = {
    within_dealer: [],
    outside_dealer: [],
    transaction_type_name_eta_compare: [],
    etaw_start_compare: [],
    etaw_end_compare: [],
  };
  modalFor = "vin";
  modalTitle = "Vin List";
  brand = "T";
  isLoading: boolean = false;
  vinEtaText = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private findCarService: FindMyCarService
  ) {
    this.vehicleData = data?.vehicleData;
    this.modalFor = data?.modalFor;
    this.brand = data?.brand;
    if (this.modalFor === "vspec") {
      this.modalTitle = "V-Spec";
    }

    this.within_dealer_vin = [];
    this.outside_dealer_vin = [];
    //if no within/outside_dealer data, treat all data as within_dealer
    if (!this.vehicleData?.within_dealer && !this.vehicleData?.outside_dealer) {
      this.vehicleData.within_dealer = data?.vehicleData.vin_compare;
      this.vehicleData.outside_dealer = ["na"];
    }

    for (let i = 0; i < this.vehicleData?.within_dealer.length; i++) {
      if (this.vehicleData.within_dealer[i] != "na") {
        //set vinEtaText if not null
        this.vinEtaText = "";
        if (this.vehicleData?.etaw_start_compare[i]) {
          this.vinEtaText =
            getFormattedDate(this.vehicleData?.etaw_start_compare[i]) +
            "-" +
            getFormattedDate(this.vehicleData?.etaw_end_compare[i]);
        }

        //push data to within_dealer_vin
        this.within_dealer_vin.push({
          vin: this.vehicleData.within_dealer[i],
          vinLabel:
            this.vehicleData.within_dealer[i] +
            " [" +
            this.map_transaction(
              this.vehicleData.transaction_type_name_eta_compare[i]
            ) +
            "]",
          vinEta: this.vinEtaText,
          vinSort: this.vehicleData?.etaw_start_compare[i],
        });
      }
    }

    for (let i = 0; i < this.vehicleData?.outside_dealer.length; i++) {
      if (this.vehicleData.outside_dealer[i] != "na") {
        //set vinEtaText if not null
        this.vinEtaText = "";
        if (this.vehicleData?.etaw_start_compare[i]) {
          this.vinEtaText =
            getFormattedDate(this.vehicleData?.etaw_start_compare[i]) +
            "-" +
            getFormattedDate(this.vehicleData?.etaw_end_compare[i]);
        }

        //push data to outside_dealer_vin
        this.outside_dealer_vin.push({
          vin: this.vehicleData.outside_dealer[i],
          vinLabel:
            this.vehicleData.outside_dealer[i] +
            " [" +
            this.map_transaction(
              this.vehicleData.transaction_type_name_eta_compare[i]
            ) +
            "]",
          vinEta: this.vinEtaText,
          vinSort: this.vehicleData?.etaw_start_compare[i],
        });
      }
    }

    this.within_dealer_vin.sort((a, b) => {
      let result = a.vinSort - b.vinSort;
      if (!a.vinSort && b.vinSort) {
        result = 1;
      } else if (!b.vinSort && a.vinSort) {
        result = -1;
      }
      return result;
    });
    console.log(this.within_dealer_vin);
  }

  ngOnInit(): void {}

  map_transaction(transaction_type) {
    if (transaction_type.includes("PPR")) {
      transaction_type = "Unbuilt";
    } else if (transaction_type.includes("PORT STOCK")) {
      transaction_type = "In transit";
    }
    transaction_type = transaction_type.toLowerCase();
    return transaction_type[0].toUpperCase() + transaction_type.slice(1);
  }
  onNavigateToVSpec(vin) {
    this.isLoading = true;
    this.findCarService.getVspecHaskKey().subscribe({
      error: (err) => {
        this.isLoading = false;
        console.log("ERROR", err);
      },
      next: (data) => {
        const hashKey = data?.tokenDetails?.hashKey;
        //const hashVal = CryptoJS.createHmac('sha256', hashKey).update(vin).digest('hex');
        const hashVal = CryptoJS.enc.Hex.stringify(
          CryptoJS.HmacSHA256(vin, hashKey)
        );
        this.isLoading = false;
        const windowReference = window.open();
        windowReference.location =
          TEMP_V_SPEC_URL + "/" + vin + "/detail?k=" + hashVal;
        //window.open(TEMP_V_SPEC_URL + '/' + vin + '/detail?k=' + hashVal, "_blank");
      },
    });
  }

  copyToClipBoard(text: string) {
    navigator.clipboard
      .writeText(text)
      .then()
      .catch((e) => console.log(e));
    console.log("copied " + text);
  }
}
