export const ANALYTICS_CAPTURE_USER_LOGIN_LOCALLY = true;
export const ANALYTICS_OVERRIDE_ON = false;

export const ANALYTICS_TEMPLATE_USER_LOGIN = {
  session_id: "",
  UserId: "user_id",
  dealer_number: "",
  family_name: "",
  first_name: "",
  given_name: "",
  last_name: "",
  preferred_name: "",
  user_status: "",
  user_type: "",
  email: "",
  primary_dealer_number: "",
  region_code: "",
};

export const ANALYTICS_TEMPLATE_USER_LOGOUT = {
  session_id: "",
};

export const ANALYTICS_TEMPLATE_SEED = {
  brand: "",
  transaction_id: "",
  session_id: "",
  user_id: "",
  model_year: "",
  model_number: "",
  series: "",
  seriesCategory: "",
  grade: "",
  dealer_code: "",
  drivetrain: "",
  option_packages: "",
  interior_color: "",
  exterior_color: "",
};

export const ANALYTICS_OPTIONS = [
  {
    name: "Logins - Total",
    value: 1,
  },
  {
    name: "Logins - Last 5 Days",
    value: 2,
  },
  {
    name: "Logins - Last 30 Days",
    value: 3,
  },
  {
    name: "BRAND - Options - Model Year",
    value: 4,
  },
  {
    name: "BRAND - Options - Model Number",
    value: 5,
  },
  {
    name: "BRAND - Options - Series",
    value: 6,
  },
  {
    name: "BRAND - Options - Series Category",
    value: 7,
  },
  {
    name: "BRAND - Options - Grade",
    value: 8,
  },
  {
    name: "BRAND - Options - Drive Train",
    value: 9,
  },
  {
    name: "BRAND - Options - Interior Color",
    value: 10,
  },
  {
    name: "BRAND - Options - Exterior Color",
    value: 11,
  },
];
